import { gql, useFragment } from "@/__generated__";
import { SubmitLead_QueryFragmentDoc } from "@/__generated__/graphql";
import { MoneyInput } from "@/components/MoneyInput";
import { PhoneNumberInput } from "@/components/PhoneNumberInput";
import { usePreventClosingDirtyForm } from "@/hooks/usePreventClosingDirtyForm";
import { INDUSTRIES, PAYMENT_METHODS, PAYMENT_VOLUME_ESTIMATES } from "@/pages/submitLead/constants";
import { usePlatformExistsForSubmitLead } from "@/pages/submitLead/usePlatformExistsForSubmitLead";
import { useSubmitALeadForm } from "@/pages/submitLead/useSubmitALeadForm";
import { Alert, Button, Flex, Grid, MultiSelect, Select, Stack, TextInput, Textarea, Title } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useParams } from "react-router";

type RouteParams = {
	platformId: string;
};

gql(`
fragment SubmitLead_Query on Query {
	platformExists(platformId: $platformId)	
}
`);

export function SubmitLead() {
	const { platformId } = useParams<RouteParams>();
	if (!platformId) {
		throw new Error("platformId is required");
	}
	const { data: existsQuery, error: existsError } = usePlatformExistsForSubmitLead(platformId);
	const existsData = useFragment(SubmitLead_QueryFragmentDoc, existsQuery);
	const optimisticExists = (!existsData || existsData.platformExists) && !existsError;
	const { form, onSubmit, isDirty, loading, data, error } = useSubmitALeadForm(platformId);
	const { confirm } = usePreventClosingDirtyForm(isDirty);
	const submissionFailed = error || data?.error;
	const submissionSuccessful = data?.success;

	return (
		<>
			<form onSubmit={onSubmit}>
				<Flex justify="center" py="xl" px={{ base: "sm", sm: "lg" }}>
					<Stack gap="xl" w="100%">
						{submissionSuccessful && (
							<Alert icon={<IconAlertCircle />} title="Success" color="green">
								Thank you! We will get in touch with you shortly.
							</Alert>
						)}
						{!optimisticExists && (
							<Alert icon={<IconAlertCircle />} title="Error" color="red">
								Invalid lead submission link
							</Alert>
						)}
						{!submissionSuccessful && optimisticExists && (
							<>
								{submissionFailed && (
									<Alert icon={<IconAlertCircle />} title="Error" color="red">
										Lead submission failed, try again later
									</Alert>
								)}
								<Grid>
									<Grid.Col span={{ base: 12, sm: 6 }}>
										<TextInput
											{...form.getInputProps("legalEntityName")}
											label="Legal entity name"
											placeholder="Legal entity name"
											key={form.key("legalEntityName")}
										/>
									</Grid.Col>
									<Grid.Col span={{ base: 12, sm: 6 }}>
										<MoneyInput
											amountProps={{
												label: "Annual revenue",
												placeholder: "Annual revenue",
												...form.getInputProps("annualRevenue"),
											}}
											currencyProps={{
												"aria-label": "Select currency",
												...form.getInputProps("annualRevenueCurrency"),
											}}
											key={form.key("annualRevenue")}
										/>
									</Grid.Col>
									<Grid.Col span={{ base: 12, sm: 6 }}>
										<Select
											data={INDUSTRIES}
											searchable
											{...form.getInputProps("industry")}
											label="Industry"
											placeholder="Industry"
											key={form.key("industry")}
										/>
									</Grid.Col>
								</Grid>
								<Title order={4}>Primary contact information</Title>
								<Grid>
									<Grid.Col span={{ base: 12, sm: 6 }}>
										<TextInput
											{...form.getInputProps("contactName")}
											label="Contact name"
											placeholder="Contact name"
											key={form.key("contactName")}
										/>
									</Grid.Col>
									<Grid.Col span={{ base: 12, sm: 6 }}>
										<TextInput
											{...form.getInputProps("contactJobTitle")}
											label="Job title"
											placeholder="Job title"
											key={form.key("contactJobTitle")}
										/>
									</Grid.Col>
									<Grid.Col span={{ base: 12, sm: 6 }}>
										<TextInput
											{...form.getInputProps("contactEmail")}
											label="Email"
											placeholder="Email"
											key={form.key("contactEmail")}
										/>
									</Grid.Col>
									<Grid.Col span={{ base: 12, sm: 6 }}>
										<PhoneNumberInput
											{...form.getInputProps("contactPhoneNumber")}
											label="Phone number"
											placeholder="Phone number"
											key={form.key("contactPhoneNumber")}
										/>
									</Grid.Col>
								</Grid>
								<Title order={4}>Payment information</Title>
								<Grid>
									<Grid.Col span={{ base: 12, sm: 6 }}>
										<Select
											data={PAYMENT_VOLUME_ESTIMATES}
											searchable
											{...form.getInputProps("estimatedMonthlyVolume")}
											label="Estimated monthly volume"
											placeholder="Estimated monthly volume"
											key={form.key("estimatedMonthlyVolume")}
										/>
									</Grid.Col>
									<Grid.Col span={{ base: 12, sm: 6 }}>
										<MultiSelect
											data={PAYMENT_METHODS}
											searchable
											clearable
											{...form.getInputProps("paymentMethodsToAccept")}
											label="Payment methods to accept"
											placeholder="Payment methods to accept"
											key={form.key("paymentMethodsToAccept")}
										/>
									</Grid.Col>
									<Grid.Col span={{ base: 12, sm: 6 }}>
										<Select
											data={["Yes", "No"]}
											searchable
											{...form.getInputProps("needRecurringPaymentCapability")}
											label="Need recurring payment capability"
											placeholder="Need recurring payment capability"
											key={form.key("needRecurringPaymentCapability")}
										/>
									</Grid.Col>
									<Grid.Col span={12}>
										<Textarea
											{...form.getInputProps("additionalRequirements")}
											label="Additional requirements (optional)"
											placeholder="Additional requirements"
											key={form.key("additionalRequirements")}
											autosize
											minRows={4}
										/>
									</Grid.Col>
								</Grid>
								<Flex justify="flex-end" gap="sm">
									<Button loading={loading} type="submit">
										Submit
									</Button>
								</Flex>
							</>
						)}
					</Stack>
				</Flex>
			</form>
			{confirm}
		</>
	);
}
