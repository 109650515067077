import { useCreatePlatformNote } from "@/pages/platforms/platform/platformNotes/useCreatePlatformNote";
import { isNotEmpty, useForm } from "@mantine/form";
import { useOs } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import type { KeyboardEvent } from "react";

type CreatePlatformNoteForm = {
	message: string;
};

export function useCreateNewPlatformNoteForm(setDirty: (dirty: boolean) => void, platformId?: string) {
	const os = useOs();
	const isMac = os === "macos";

	const form = useForm<CreatePlatformNoteForm>({
		mode: "uncontrolled",
		initialValues: {
			message: "",
		},
		validate: {
			message: isNotEmpty("Please enter a message"),
		},
		onValuesChange: () => {
			setDirty(form.isDirty());
		},
	});

	const { createPlatformNote, loading } = useCreatePlatformNote(platformId);
	const onSubmit = form.onSubmit(async (values: CreatePlatformNoteForm) => {
		if (!platformId) {
			return;
		}
		try {
			const { data } = await createPlatformNote({
				variables: {
					input: {
						platformId,
						message: values.message,
					},
				},
			});
			if (data?.platformNote?.create?.success) {
				notifications.show({
					title: "Platform note created",
					message: "Platform note successfully submitted",
				});
				form.reset();
				setDirty(false);
			} else {
				notifications.show({
					title: `Failed to create platform note: ${data?.platformNote?.create?.error?.code}`,
					message: data?.platformNote?.create?.error?.message,
					color: "red",
				});
			}
		} catch (error) {
			notifications.show({
				title: "Failed to create platform note",
				message: error instanceof Error ? error.message : "An unexpected error occurred",
				color: "red",
			});
		}
	});

	const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
			e.preventDefault();
			void onSubmit();
		}
	};

	return {
		form,
		onSubmit,
		loading,
		onKeyDown,
		isMac,
	};
}
