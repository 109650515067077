import { type FragmentType, gql, useFragment } from "@/__generated__";
import { PlatformForm_PlatformFragmentDoc, PlatformStatus } from "@/__generated__/graphql";
import { MoneyInput } from "@/components/MoneyInput";
import { PhoneNumberInput } from "@/components/PhoneNumberInput";
import { snakeToHumanReadable } from "@/pages/common/utils";
import { usePlatformForm } from "@/pages/platforms/platform/platformForm/usePlatformForm";
import type { ApolloError } from "@apollo/client";
import { Alert, Button, Flex, Grid, Select, Stack, TextInput, Title } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

const PlatformForm_PlatformFragment = gql(`
fragment PlatformForm_Platform on Platform {
	id
	name
	contactName
	contactEmail
	contactPhoneNumber
	projectedRevenue {
		amount
		currency
	}
	status
}
`);

type PlatformFormProps = {
	error: ApolloError | undefined;
	isEditing: boolean;
	onClose: () => void;
	platform?: FragmentType<typeof PlatformForm_PlatformFragment> | null;
	setDirty: (dirty: boolean) => void;
};

export function PlatformForm({ error, isEditing, onClose, platform, setDirty }: PlatformFormProps) {
	const platformData = useFragment(PlatformForm_PlatformFragmentDoc, platform);
	const { form, onSubmit, loading: submitting } = usePlatformForm(onClose, setDirty, platformData);
	return (
		<form onSubmit={onSubmit}>
			<Stack gap="xl" w="100%">
				<Title order={1}>{isEditing ? "Edit platform" : "Create a new platform"}</Title>
				{error && (
					<Alert icon={<IconAlertCircle />} title="Error" color="red">
						{error.message}
					</Alert>
				)}
				{!error && (
					<>
						<Grid>
							<Grid.Col span={12}>
								<TextInput
									{...form.getInputProps("name")}
									label="Name"
									placeholder="Name"
									withAsterisk
									key={form.key("name")}
								/>
							</Grid.Col>
							{isEditing && (
								<Grid.Col span={{ base: 12, sm: 6 }}>
									<Select
										{...form.getInputProps("status")}
										label="Status"
										placeholder="Status"
										data={Object.keys(PlatformStatus).map((status) => ({
											value: status,
											label: snakeToHumanReadable(status),
										}))}
										key={form.key("status")}
										withAsterisk
									/>
								</Grid.Col>
							)}
							<Grid.Col span={{ base: 12, sm: 6 }}>
								<MoneyInput
									amountProps={{
										label: "Projected revenue",
										placeholder: "Projected revenue",
										...form.getInputProps("projectedRevenue"),
									}}
									currencyProps={{
										"aria-label": "Select currency",
										...form.getInputProps("projectedRevenueCurrency"),
									}}
									key={form.key("projectedRevenue")}
								/>
							</Grid.Col>
						</Grid>
						<Title order={4}>Contact information</Title>
						<Grid>
							<Grid.Col span={{ base: 12, sm: 6 }}>
								<TextInput
									{...form.getInputProps("contactName")}
									label="Contact name"
									placeholder="Contact name"
									key={form.key("contactName")}
								/>
							</Grid.Col>
							<Grid.Col span={{ base: 12, sm: 6 }}>
								<TextInput
									{...form.getInputProps("contactEmail")}
									label="Contact email"
									placeholder="Contact email"
									key={form.key("contactEmail")}
								/>
							</Grid.Col>
							<Grid.Col span={{ base: 12, sm: 6 }}>
								<PhoneNumberInput
									{...form.getInputProps("contactPhoneNumber")}
									label="Contact phone number"
									placeholder="Contact phone number"
									key={form.key("contactPhoneNumber")}
								/>
							</Grid.Col>
						</Grid>
						<Flex direction="row-reverse" gap="sm">
							<Button loading={submitting} type="submit">
								Save platform
							</Button>
							<Button type="button" variant="subtle" color="gray" onClick={onClose}>
								Cancel
							</Button>
						</Flex>
					</>
				)}
			</Stack>
		</form>
	);
}
