import { type FragmentType, gql, useFragment } from "@/__generated__";
import { GetDisplayName_UserFragmentDoc } from "@/__generated__/graphql";
import { generatePath } from "react-router";

const GetDisplayName_UserFragment = gql(`
fragment getDisplayName_User on User {
	id
	firstName
	lastName
	email
}
`);

export function getDisplayName(user?: FragmentType<typeof GetDisplayName_UserFragment> | null): string {
	const userFragment = useFragment(GetDisplayName_UserFragmentDoc, user);
	if (userFragment?.firstName && userFragment?.lastName) {
		return `${userFragment.firstName} ${userFragment.lastName}`;
	}
	return userFragment?.email || "";
}

export function snakeToHumanReadable(text?: string | null): string {
	if (!text) {
		return "";
	}
	const words = text.toLowerCase().split("_");
	return [words[0].charAt(0).toUpperCase() + words[0].slice(1), ...words.slice(1)].join(" ");
}

export function generateFullAppUrl(basePath: string, params: Record<string, string | number>): string {
	const baseUrl = window.location.origin;
	const relativePath = generatePath(basePath, params);
	return `${baseUrl}${relativePath}`;
}

export function parseParamsToObject(string?: string): Record<string, string> {
	if (!string || string.length === 0) {
		return {};
	}
	const cleanHash = string.startsWith("#") || string.startsWith("?") ? string.slice(1) : string;
	const params = new URLSearchParams(cleanHash);
	const parsedObject: Record<string, string> = {};
	for (const [key, value] of params.entries()) {
		parsedObject[key] = value;
	}

	return parsedObject;
}
