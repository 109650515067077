import { gql } from "@/__generated__";
import { addNewPlatformFirstInCache } from "@/pages/platforms/cacheUtils";
import { useMutation } from "@apollo/client";

const PlatformForm_CreatePlatform = gql(`
mutation createPlatform($input: CreatePlatformInput!) {
	platform {
		create(input: $input) {
			success
			error {
				code
			}
			platform {
				...addNewPlatformFirstInCache_Platform
			}
		}
	}
}
`);

const PlatformForm_UpdatePlatform = gql(`
mutation updatePlatform($input: UpdatePlatformInput!) {
	platform {
		update(input: $input) {
			success
			error {
				code
			}
			platform {
				...addNewPlatformFirstInCache_Platform
			}
		}
	}
}
`);

export function useSavePlatformForm() {
	const [createPlatform, { loading, error }] = useMutation(PlatformForm_CreatePlatform, {
		update(cache, { data }) {
			const create = data?.platform.create;
			if (create?.success && create?.platform) {
				addNewPlatformFirstInCache(cache, create.platform);
			}
		},
	});

	const [updatePlatform, { loading: updateLoading, error: updateError }] = useMutation(PlatformForm_UpdatePlatform);

	return {
		createPlatform,
		updatePlatform,
		loading: loading || updateLoading,
		error: error || updateError,
	};
}
