import { PaymentMethod, PaymentVolumeEstimate } from "@/__generated__/graphql";
import { INDUSTRY_VALUES } from "@/pages/submitLead/constants";
import { useSubmitLead } from "@/pages/submitLead/useSubmitLead";
import { isEmail, isInRange, isNotEmpty, useForm } from "@mantine/form";
import { useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";

type SubmitLeadFormType = {
	legalEntityName: string;
	annualRevenue: number;
	annualRevenueCurrency: string;
	industry: string;
	contactName: string;
	contactJobTitle: string;
	contactEmail: string;
	contactPhoneNumber: string;
	estimatedMonthlyVolume: PaymentVolumeEstimate;
	paymentMethodsToAccept: PaymentMethod[];
	needRecurringPaymentCapability: string;
	additionalRequirements: string | null;
};

export function useSubmitALeadForm(platformId: string) {
	const [isDirty, setDirty] = useState(false);
	const initialValues = {
		legalEntityName: "",
		annualRevenue: 0,
		annualRevenueCurrency: "AUD",
		industry: "",
		contactName: "",
		contactJobTitle: "",
		contactEmail: "",
		contactPhoneNumber: "",
		estimatedMonthlyVolume: PaymentVolumeEstimate.UNDER_10_000,
		paymentMethodsToAccept: [],
		needRecurringPaymentCapability: "",
		additionalRequirements: "",
	};
	const form = useForm<SubmitLeadFormType>({
		mode: "controlled",
		initialValues,
		validate: {
			legalEntityName: isNotEmpty("Legal entity name is required"),
			annualRevenue: isInRange({ min: 1 }, "Annual revenue must be a positive value"),
			annualRevenueCurrency: isNotEmpty(""),
			industry: (value) => (!INDUSTRY_VALUES.includes(value) ? "Select an industry" : false),
			contactName: isNotEmpty("Contact name is required"),
			contactJobTitle: isNotEmpty("Job title is required"),
			contactEmail: isEmail("Enter a valid email"),
			contactPhoneNumber: (value) => (isPossiblePhoneNumber(value) ? null : "Enter a valid phone number"),
			estimatedMonthlyVolume: (value) =>
				!value || !Object.values(PaymentVolumeEstimate).includes(PaymentVolumeEstimate[value])
					? "Select a value"
					: false,
			paymentMethodsToAccept: (value) =>
				value.length === 0
					? "Select at least one payment method"
					: !value.every((v) => Object.values(PaymentMethod).includes(v))
						? "Select a valid value"
						: false,
			needRecurringPaymentCapability: (value) =>
				!value || !["Yes", "No"].includes(value) ? "Need recurring payment capability is required" : false,
		},
		onValuesChange: () => {
			setDirty(form.isDirty());
		},
	});
	const { submitLead, loading, error, data } = useSubmitLead();
	const onSubmit = async (values: SubmitLeadFormType) => {
		try {
			await submitLead({
				variables: {
					input: {
						platformId,
						legalEntityName: values.legalEntityName,
						annualRevenue: {
							amount: values.annualRevenue,
							currency: values.annualRevenueCurrency,
						},
						industry: values.industry,
						contactName: values.contactName,
						contactEmail: values.contactEmail,
						contactJobTitle: values.contactJobTitle,
						contactPhoneNumber: values.contactPhoneNumber,
						estimatedMonthlyPaymentVolume: values.estimatedMonthlyVolume,
						paymentMethodsToAccept: values.paymentMethodsToAccept,
						hasNeedForRecurringPayments: values.needRecurringPaymentCapability === "Yes",
						additionalRequirements: values.additionalRequirements,
					},
				},
			});
			form.resetDirty();
			setDirty(false);
		} catch (error) {
			console.error("Error submitting form", error);
		}
	};
	return {
		form,
		onSubmit: form.onSubmit(onSubmit),
		isDirty,
		loading,
		data: data?.merchant?.submitLead,
		error: error,
	};
}
