import { UserType } from "@/__generated__/graphql";
import { useAuth } from "@/auth/useAuth";
import { paths } from "@/paths";
import { Button, Group, Stack, Title } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { Outlet, generatePath } from "react-router";
import { Link } from "react-router-dom";

export function Platforms() {
	const { user } = useAuth();
	return (
		<Stack gap="xl">
			<Group justify="space-between" align="center">
				<Title order={1}>Platforms</Title>
				{user?.type === UserType.FASTLANEIQ && user?.hasWriteAccess && (
					<Button leftSection={<IconPlus />} component={Link} to={generatePath(paths.platform)}>
						Create new platform
					</Button>
				)}
			</Group>
			<Outlet />
		</Stack>
	);
}
