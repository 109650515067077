import { type FragmentType, gql, useFragment } from "@/__generated__";
import { PlatformNote_PlatformNoteFragmentDoc } from "@/__generated__/graphql";
import { getDisplayName } from "@/pages/common/utils";
import { Avatar, Group, Paper, Text, TypographyStylesProvider } from "@mantine/core";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";
import classes from "./PlatformNote.module.css";

export const PlatformNote_PlatformNoteFragment = gql(`
fragment PlatformNote_PlatformNote on PlatformNote {
    id
    message
    createdAt
    createdBy {
        ...getDisplayName_User
    }
}
`);

type PlatformNoteProps = {
	note: FragmentType<typeof PlatformNote_PlatformNoteFragment>;
};

export function PlatformNote({ note }: PlatformNoteProps) {
	const noteData = useFragment(PlatformNote_PlatformNoteFragmentDoc, note);
	const displayName = getDisplayName(noteData.createdBy);
	return (
		<Paper withBorder radius="md" className={classes.comment}>
			<Group>
				<Avatar name={displayName} color="initials" radius="xl" />
				<div>
					<Text fz="sm">{displayName}</Text>
					<Text fz="xs" c="dimmed">
						{formatDistanceToNow(noteData.createdAt, {
							addSuffix: true,
						})}
					</Text>
				</div>
			</Group>
			<TypographyStylesProvider className={classes.body}>
				<div className={classes.content}>{noteData.message}</div>
			</TypographyStylesProvider>
		</Paper>
	);
}
