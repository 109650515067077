import { gql } from "@/__generated__";
import { useQuery } from "@apollo/client";

const Platform_GetData = gql(`
query Platform_GetData($platformId: UUID!) {
	...Platform_GetData
}
`);

export function usePlatformData(platformId?: string) {
	const { data, loading, error } = useQuery(Platform_GetData, {
		errorPolicy: "all",
		variables: {
			platformId,
		},
		skip: !platformId,
	});
	return { data, error, loading };
}
