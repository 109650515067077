import { MerchantStage, MerchantStatus, type MerchantsTable_MerchantFragment } from "@/__generated__/graphql";
import { AgeCell } from "@/components/table/AgeCell";
import { MoneyCell } from "@/components/table/MoneyCell";
import { TimestampCell } from "@/components/table/TimestampCell";
import { getDisplayName, snakeToHumanReadable } from "@/pages/common/utils";
import { MerchantStageCell } from "@/pages/merchants/merchantsTable/MerchantStageCell";
import { MerchantStatusCell } from "@/pages/merchants/merchantsTable/MerchantStatusCell";
import type { MRT_ColumnDef } from "mantine-react-table";
import { useMemo } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";

export function useMerchantsTableColumns() {
	const columns: Array<MRT_ColumnDef<MerchantsTable_MerchantFragment>> = useMemo(
		() => [
			{
				accessorKey: "legalEntityName",
				header: "Legal entity name",
				enableHiding: false,
				minSize: 250,
			},
			{
				accessorKey: "stage",
				accessorFn: (row) => snakeToHumanReadable(row.stage),
				id: "stage",
				grow: false,
				size: 170,
				header: "Stage",
				filterVariant: "multi-select",
				mantineFilterMultiSelectProps: {
					data: Object.values(MerchantStage).map((key) => ({
						value: key,
						label: snakeToHumanReadable(key),
					})),
				},
				Cell: MerchantStageCell,
			},
			{
				accessorKey: "status",
				id: "status",
				header: "Status",
				filterVariant: "multi-select",
				mantineFilterMultiSelectProps: {
					data: Object.values(MerchantStatus).map((key) => ({
						value: key,
						label: snakeToHumanReadable(key),
					})),
				},
				grow: false,
				size: 170,
				Cell: MerchantStatusCell,
			},
			{
				accessorFn: (m) => m.leadData?.annualRevenue,
				id: "leadData.annualRevenue",
				header: "Projected revenue",
				Cell: MoneyCell,
				size: 200,
				filterVariant: "range",
				mantineFilterTextInputProps: { type: "number" },
				grow: false,
			},
			{
				accessorFn: (m) => m.platform?.name,
				id: "platform.name",
				header: "Platform",
				size: 200,
				grow: false,
			},
			{
				accessorKey: "createdAt",
				header: "Age",
				size: 250,
				grow: false,
				filterVariant: "date-range",
				Cell: AgeCell,
			},
			{
				accessorFn: (p) => getDisplayName(p.createdBy),
				header: "Created by",
				id: "createdBy",
				size: 200,
				grow: false,
			},
			{
				accessorKey: "updatedAt",
				header: "Updated",
				size: 250,
				grow: false,
				filterVariant: "date-range",
				Cell: TimestampCell,
			},
			{
				accessorFn: (p) => getDisplayName(p.lastUpdatedBy),
				header: "Last updated by",
				id: "lastUpdatedBy",
				size: 200,
				grow: false,
			},
			{
				accessorFn: (m) => m.leadData?.industry,
				id: "leadData.industry",
				header: "Industry",
				enableColumnFilter: false,
				enableSorting: false,
			},
			{
				accessorFn: (m) => m.leadData?.contactName,
				id: "leadData.contactName",
				header: "Contact name",
				enableColumnFilter: false,
				enableSorting: false,
			},
			{
				accessorFn: (m) => m.leadData?.contactEmail,
				id: "leadData.contactEmail",
				header: "Contact email",
				enableColumnFilter: false,
				enableSorting: false,
			},
			{
				accessorFn: (m) =>
					m.leadData?.contactPhoneNumber ? formatPhoneNumberIntl(m.leadData?.contactPhoneNumber) : "",
				id: "leadData.contactPhoneNumber",
				header: "Contact phone number",
				enableColumnFilter: false,
				enableSorting: false,
			},
			{
				accessorFn: (m) => m.leadData?.contactJobTitle,
				id: "leadData.contactJobTitle",
				header: "Contact job title",
				enableColumnFilter: false,
				enableSorting: false,
			},
			{
				accessorFn: (m) => m.leadData?.estimatedMonthlyPaymentVolume,
				id: "leadData.estimatedMonthlyPaymentVolume",
				header: "Estimated monthly payment volume",
				enableColumnFilter: false,
				enableSorting: false,
			},
			{
				accessorFn: (m) => m.leadData?.paymentMethodsToAccept,
				id: "leadData.paymentMethodsToAccept",
				header: "Payment methods to accept",
				enableColumnFilter: false,
				enableSorting: false,
			},
			{
				accessorFn: (m) => m.leadData?.hasNeedForRecurringPayments,
				id: "leadData.hasNeedForRecurringPayments",
				header: "Need for recurring payments",
				enableColumnFilter: false,
				enableSorting: false,
			},
			{
				accessorFn: (m) => m.leadData?.additionalRequirements,
				id: "leadData.additionalRequirements",
				header: "Additional requirements",
				enableColumnFilter: false,
				enableSorting: false,
			},
		],
		[],
	);
	const initialColumnVisibility = {
		createdBy: false,
		"leadData.industry": false,
		"leadData.contactName": false,
		"leadData.contactEmail": false,
		"leadData.contactPhoneNumber": false,
		"leadData.contactJobTitle": false,
		"leadData.estimatedMonthlyPaymentVolume": false,
		"leadData.paymentMethodsToAccept": false,
		"leadData.hasNeedForRecurringPayments": false,
		"leadData.additionalRequirements": false,
	};
	return { columns, initialColumnVisibility };
}
