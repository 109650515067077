import { type FragmentType, gql, useFragment } from "@/__generated__";
import {
	AddNoteFirstInCache_PlatformNoteFragmentDoc,
	PlatformNote_PlatformNoteFragmentDoc,
} from "@/__generated__/graphql";
import { PlatformNote_PlatformNoteFragment } from "@/pages/platforms/platform/platformNotes/PlatformNote";
import type { ApolloCache } from "@apollo/client";

const addNoteFirstInCache_PlatformNote = gql(`
fragment addNoteFirstInCache_PlatformNote on PlatformNote {
	...PlatformNote_PlatformNote
}
`);

export function addNewNoteFirstInCache(
	cache: ApolloCache<FragmentType<typeof addNoteFirstInCache_PlatformNote>>,
	note: FragmentType<typeof addNoteFirstInCache_PlatformNote>,
	platformId: string,
) {
	const rootQueryId = cache.identify({ __typename: "Platform", id: platformId });
	const noteData = useFragment(AddNoteFirstInCache_PlatformNoteFragmentDoc, note);
	cache.modify({
		id: rootQueryId,
		fields: {
			notes: (existingNotes) => {
				const ref = cache.writeFragment({
					id: cache.identify(noteData),
					fragment: PlatformNote_PlatformNoteFragment,
					fragmentName: "PlatformNote_PlatformNote",
					data: useFragment(PlatformNote_PlatformNoteFragmentDoc, noteData),
				});
				if (!existingNotes) {
					return [{ ref }];
				}
				return [{ ref }, ...existingNotes];
			},
		},
	});
}
