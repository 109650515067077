import { gql } from "@/__generated__";
import { addNewNoteFirstInCache } from "@/pages/platforms/platform/platformNotes/cacheUtils";
import { useMutation } from "@apollo/client";

export const CreatePlatformNoteMutation = gql(`
mutation CreatePlatformNote($input: CreatePlatformNoteInput!) {
    platformNote {
        create(input: $input) {
            success
            error {
                code
                message
            }
            note {
                id
                ...addNoteFirstInCache_PlatformNote
            }
        }
    }
}
`);

export function useCreatePlatformNote(platformId?: string) {
	const [createPlatformNote, { loading }] = useMutation(CreatePlatformNoteMutation, {
		update: (cache, { data }) => {
			const createNote = data?.platformNote?.create;
			if (createNote?.success && createNote?.note && platformId) {
				addNewNoteFirstInCache(cache, createNote.note, platformId);
			}
		},
	});

	return {
		createPlatformNote,
		loading,
	};
}
