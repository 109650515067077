import { gql, useFragment } from "@/__generated__";
import { Platform_GetDataFragmentDoc } from "@/__generated__/graphql";
import { usePreventClosingDirtyForm } from "@/hooks/usePreventClosingDirtyForm";
import { PlatformForm } from "@/pages/platforms/platform/platformForm/PlatformForm";
import { PlatformNotes } from "@/pages/platforms/platform/platformNotes/PlatformNotes";
import { usePlatformData } from "@/pages/platforms/platform/usePlatformData";
import { paths } from "@/paths";
import { Divider, Grid, LoadingOverlay, Modal } from "@mantine/core";
import { useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import classes from "./Platform.module.css";

gql(`
fragment Platform_GetData on Query {
	platform(platformId: $platformId) {
		...PlatformForm_Platform
		...PlatformNotes_Platform
	}
}
`);

export function Platform() {
	const { platformId } = useParams();
	const navigate = useNavigate();
	const { data, error, loading } = usePlatformData(platformId);
	const queryData = useFragment(Platform_GetDataFragmentDoc, data);
	const onClose = () => navigate(generatePath(paths.platforms));
	const [isDirty, setDirty] = useState({ form: false, notes: false });
	const setDirtyForm = (dirty: boolean) => setDirty((prev) => ({ ...prev, form: dirty }));
	const setDirtyNotes = (dirty: boolean) => setDirty((prev) => ({ ...prev, notes: dirty }));
	const { protectedClose, confirm } = usePreventClosingDirtyForm(isDirty.form || isDirty.notes, onClose);
	const isEditing = !!platformId;
	if (loading) {
		return (
			<Modal.Root onClose={onClose} opened fullScreen removeScrollProps={{ allowPinchZoom: true }}>
				<Modal.Overlay />
				<Modal.Content>
					<LoadingOverlay visible />
				</Modal.Content>
			</Modal.Root>
		);
	}
	return (
		<Modal opened onClose={protectedClose} fullScreen removeScrollProps={{ allowPinchZoom: true }}>
			<Grid gutter="xl" className={classes.gridRoot} classNames={{ inner: classes.gridInner }}>
				<Grid.Col span={{ base: 12, md: 8 }} offset={{ base: 0, md: isEditing ? 0 : 2 }} className={classes.column}>
					<PlatformForm
						error={error}
						isEditing={isEditing}
						onClose={protectedClose}
						platform={queryData?.platform}
						setDirty={setDirtyForm}
					/>
				</Grid.Col>
				{isEditing && queryData?.platform && (
					<Grid.Col span={{ base: 12, md: 4 }} className={classes.column}>
						<Divider orientation="vertical" className={classes.divider} />
						<PlatformNotes platform={queryData.platform} setDirty={setDirtyNotes} />
					</Grid.Col>
				)}
			</Grid>
			{confirm}
		</Modal>
	);
}
