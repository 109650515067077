import { useCreateNewPlatformNoteForm } from "@/pages/platforms/platform/platformNotes/useCreateNewPlatformNoteForm";
import { Button, Flex, Kbd, Stack, Text, Textarea } from "@mantine/core";

type CreateNewPlatformNoteProps = {
	platformId?: string;
	setDirty: (dirty: boolean) => void;
};

export function CreateNewPlatformNote({ platformId, setDirty }: CreateNewPlatformNoteProps) {
	const { form, onSubmit, loading, onKeyDown, isMac } = useCreateNewPlatformNoteForm(setDirty, platformId);
	const platformExists = !!platformId;
	return (
		<form style={{ display: "contents" }} onSubmit={onSubmit}>
			<Stack gap="sm">
				<Textarea
					{...form.getInputProps("message")}
					label="Message"
					onKeyDown={onKeyDown}
					withAsterisk
					placeholder={platformExists ? "Add a note" : "Save platform to add notes"}
					key={form.key("message")}
					disabled={!platformExists}
					autosize
					minRows={4}
				/>
				<Flex justify="space-between">
					<Text c="dimmed" size="xs">
						<Kbd size="xs">{isMac ? "⌘" : "Ctrl"}</Kbd> + <Kbd size="xs">Enter</Kbd> to submit
					</Text>
					<Button type="submit" disabled={!platformExists} loading={loading}>
						Submit note
					</Button>
				</Flex>
			</Stack>
		</form>
	);
}
