import { Select, TextInput, type TextInputProps, rem } from "@mantine/core";
import type { E164Number } from "libphonenumber-js";
import { useState } from "react";
import { type Country, getCountries, getCountryCallingCode } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";

const countryCodesData = getCountries().map((country) => ({
	value: country,
	label: `${getFlagEmoji(country)} ${en[country]} (+${getCountryCallingCode(country)})`,
}));

type PhoneNumberInputProps = Omit<Omit<TextInputProps, "onChange">, "value"> & {
	value?: string | E164Number;
	onChange: (value?: E164Number) => void;
};

/**
 * This component can be used to input a phone number. It uses the react-phone-number-input library.
 * The only caveat is that this component doens't support being uncontrolled so all form using it must be controlled.
 */
export function PhoneNumberInput(props: PhoneNumberInputProps) {
	const [country, setCountry] = useState<Country | undefined>("AU");
	const select = (
		<Select
			aria-label="Select country code"
			data={countryCodesData}
			rightSectionWidth={28}
			styles={{
				root: {
					marginTop: props.error ? rem(5) : 0,
				},
				input: {
					fontWeight: 500,
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0,
					width: rem(92),
					marginRight: rem(-2),
				},
			}}
			comboboxProps={{ width: 300, position: "bottom-end" }}
			searchable
			error={!!props.error}
			value={country}
			onChange={(value) => setCountry(value as Country)}
		/>
	);

	return (
		<PhoneInput {...props} rightSection={select} rightSectionWidth={92} country={country} inputComponent={TextInput} />
	);
}

function getFlagEmoji(countryCode: string) {
	const codePoints = countryCode
		.toUpperCase()
		.split("")
		.map((char) => 127397 + char.charCodeAt(0));
	return String.fromCodePoint(...codePoints);
}
