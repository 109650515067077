import { type FragmentType, gql, useFragment } from "@/__generated__";
import { PlatformNotes_PlatformFragmentDoc } from "@/__generated__/graphql";
import { CreateNewPlatformNote } from "@/pages/platforms/platform/platformNotes/CreateNewPlatformNote";
import { PlatformNote } from "@/pages/platforms/platform/platformNotes/PlatformNote";
import { Box, ScrollArea, Stack, Text, Title } from "@mantine/core";
import classes from "./PlatformNotes.module.css";

export const PlatformNotes_Platform = gql(`
fragment PlatformNotes_Platform on Platform {
    id
    notes {
        id
        ...PlatformNote_PlatformNote
    }
}
`);

type PlatformNotesProps = {
	platform?: FragmentType<typeof PlatformNotes_Platform> | null;
	setDirty: (dirty: boolean) => void;
};

export function PlatformNotes({ platform, setDirty }: PlatformNotesProps) {
	const platformData = useFragment(PlatformNotes_PlatformFragmentDoc, platform);
	const notes = platformData?.notes || [];
	return (
		<Stack justify="space-between" className={classes.wrapper}>
			<Stack gap="lg">
				<Title order={4}>Notes</Title>
				{notes.length === 0 && (
					<Text size="sm" c="dimmed" fs="italic">
						No notes added yet
					</Text>
				)}
			</Stack>
			<ScrollArea
				type="hover"
				style={{
					height: "100%",
					overscrollBehaviorY: "contain",
					scrollSnapType: "y mandatory",
					WebkitOverflowScrolling: "touch",
				}}
			>
				<Stack gap="sm">
					{notes.map((note) => (
						<PlatformNote key={note.id} note={note} />
					))}
				</Stack>
			</ScrollArea>
			<Box style={{ flexGrow: 0 }}>
				<CreateNewPlatformNote platformId={platformData?.id} setDirty={setDirty} />
			</Box>
		</Stack>
	);
}
